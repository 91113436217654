import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 3264.000000 1632.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1632.000000) scale(0.100000,-0.100000)">
  



  <path d="M18130 14577 c-285 -57 -515 -275 -565 -535 -35 -181 -7 -322 96
-487 78 -125 198 -215 383 -287 40 -15 61 -30 67 -46 5 -13 9 -136 9 -274 0
-280 -3 -297 -68 -346 l-35 -27 -736 -6 c-500 -3 -746 -9 -768 -16 -140 -49
-292 -191 -323 -302 -6 -22 -10 -297 -10 -732 0 -649 -1 -698 -17 -718 -23
-28 -126 -63 -172 -59 l-36 3 -5 285 c-4 250 -7 288 -22 310 -10 13 -18 30
-18 37 0 21 -59 101 -108 144 -66 58 -172 106 -263 118 -41 5 -289 9 -553 10
-434 2 -480 3 -502 19 -13 9 -27 28 -30 42 -9 34 -46 110 -64 132 -8 10 -33
39 -55 66 -67 82 -185 164 -289 202 -269 98 -534 62 -756 -103 -60 -44 -130
-112 -130 -125 0 -5 -9 -17 -20 -27 -11 -10 -20 -23 -20 -28 0 -6 -6 -18 -13
-26 -15 -18 -52 -112 -62 -156 -4 -16 -14 -38 -23 -49 -24 -26 -24 -184 1
-197 9 -5 19 -26 23 -47 3 -20 14 -55 24 -77 10 -22 21 -47 24 -55 7 -15 17
-33 50 -85 11 -16 52 -62 92 -101 56 -54 96 -83 170 -119 119 -59 187 -76 334
-82 119 -5 224 10 300 44 85 38 107 49 145 73 73 46 195 163 195 187 0 7 4 13
10 13 5 0 16 15 24 34 8 18 19 37 25 40 6 4 11 17 11 29 0 13 13 38 29 57 l29
35 463 3 c510 4 547 1 602 -49 l32 -29 0 -374 0 -374 -50 -49 c-70 -67 -126
-137 -168 -211 -21 -35 -37 -66 -37 -69 0 -3 -7 -19 -14 -36 -52 -112 -84
-302 -70 -409 5 -45 4 -48 -20 -54 l-26 -6 0 -2263 c0 -1503 -4 -2298 -10
-2366 -28 -277 -131 -518 -275 -647 -289 -257 -828 -251 -1147 13 -218 179
-308 402 -273 669 27 202 118 356 291 496 155 124 325 183 529 184 95 0 137
-5 200 -23 98 -27 198 -71 281 -122 l64 -39 0 1099 0 1098 -167 12 c-402 28
-624 24 -907 -15 -413 -57 -836 -219 -1201 -458 -252 -166 -560 -445 -745
-676 -263 -329 -440 -701 -524 -1100 -72 -348 -61 -791 30 -1170 82 -341 268
-702 507 -985 104 -123 138 -156 166 -158 11 -1 21 -6 21 -12 0 -6 14 -10 30
-9 17 1 30 -2 30 -7 0 -5 9 -9 19 -9 11 0 23 -5 26 -10 4 -6 10 -8 15 -5 5 3
11 1 15 -4 3 -5 14 -8 25 -6 12 2 20 -2 20 -10 0 -9 6 -12 15 -9 8 3 21 -1 30
-10 9 -8 23 -12 31 -9 9 3 20 0 24 -7 4 -7 19 -14 33 -14 14 -1 31 -6 38 -12
8 -6 21 -10 29 -9 8 0 21 -5 27 -11 7 -7 21 -11 30 -10 10 2 23 -3 30 -10 7
-7 21 -11 30 -10 10 2 21 -1 24 -6 3 -5 12 -11 20 -12 8 -2 22 -6 31 -10 9 -4
22 -9 30 -10 7 -2 24 -8 38 -13 14 -6 33 -9 43 -7 9 1 17 -1 17 -7 0 -5 9 -9
19 -9 11 0 21 -4 23 -9 2 -6 14 -12 28 -14 14 -2 33 -7 42 -11 9 -4 22 -9 30
-10 7 -2 21 -6 30 -10 9 -4 22 -9 30 -11 7 -2 23 -6 35 -10 12 -4 25 -6 29 -5
4 1 10 -3 13 -8 4 -6 18 -12 31 -14 14 -2 40 -11 59 -21 19 -9 42 -17 51 -17
9 0 20 -3 24 -7 3 -4 16 -8 27 -10 11 -2 27 -9 35 -17 7 -8 23 -12 34 -9 12 3
20 0 20 -7 0 -7 7 -10 15 -7 8 4 17 2 20 -4 4 -5 10 -7 15 -4 4 3 13 -2 20 -9
6 -8 20 -13 31 -11 10 2 19 -1 19 -7 0 -5 6 -8 14 -5 7 3 19 0 25 -6 6 -6 11
-8 11 -3 0 4 7 1 15 -8 8 -8 24 -13 35 -11 11 2 20 -1 20 -7 0 -5 6 -8 14 -5
7 3 19 0 25 -6 6 -6 11 -7 11 -1 0 5 4 3 9 -4 4 -7 14 -15 22 -16 8 -2 22 -6
31 -10 9 -4 22 -9 30 -10 7 -2 21 -6 30 -10 9 -4 22 -9 30 -11 7 -2 23 -6 35
-10 12 -4 25 -6 29 -5 4 1 10 -3 13 -8 4 -6 18 -13 31 -15 14 -3 35 -8 47 -12
12 -4 25 -6 29 -5 4 1 10 -3 13 -8 4 -6 18 -13 31 -15 14 -2 36 -9 50 -14 14
-6 28 -9 33 -6 4 2 7 -3 7 -11 0 -10 6 -13 16 -9 9 3 20 0 25 -8 5 -9 9 -10 9
-2 0 7 18 2 47 -12 25 -13 50 -22 55 -19 4 3 8 0 8 -5 0 -6 12 -11 26 -11 14
0 32 -7 40 -15 8 -8 22 -11 30 -8 9 3 19 1 23 -5 4 -7 19 -13 34 -14 15 -1 27
-6 27 -10 0 -4 8 -8 18 -8 10 0 22 -4 27 -9 6 -5 24 -11 40 -13 17 -1 36 -8
43 -15 7 -6 21 -10 32 -8 11 2 20 0 20 -6 0 -5 8 -9 18 -9 10 0 22 -4 28 -10
5 -5 19 -11 31 -13 13 -2 26 -6 29 -10 4 -4 17 -7 30 -7 12 0 24 -4 26 -9 2
-5 59 -1 128 9 391 57 805 203 1175 413 59 34 60 34 85 17 38 -27 279 -145
325 -160 22 -7 45 -16 50 -20 17 -12 225 -77 332 -104 122 -31 357 -71 514
-87 147 -16 688 -17 841 -1 571 59 1056 222 1453 490 301 203 566 475 748 770
156 252 278 575 331 877 51 284 49 248 53 1464 l4 1144 -37 38 c-21 22 -43 39
-49 39 -6 0 -26 16 -45 35 -18 19 -36 32 -39 30 -3 -3 -26 16 -53 42 -26 26
-48 43 -48 38 0 -5 -22 13 -50 40 -27 27 -50 45 -50 41 0 -5 -7 -3 -15 4 -8 7
-12 17 -8 24 3 6 2 8 -2 3 -5 -4 -21 5 -37 20 -15 15 -28 23 -28 18 0 -5 -22
13 -50 40 -27 27 -50 45 -50 40 0 -5 -18 9 -40 30 -22 21 -40 35 -40 30 0 -5
-19 10 -43 33 -24 23 -47 39 -52 35 -4 -5 -5 -3 -2 3 4 7 0 17 -8 24 -8 7 -15
9 -15 4 0 -4 -16 7 -35 26 -19 19 -35 30 -35 25 0 -5 -22 12 -48 38 -27 26
-51 45 -55 42 -4 -3 -5 -2 -2 2 6 8 -15 34 -25 28 -4 -2 -22 11 -40 30 -19 19
-39 35 -45 35 -6 0 -28 18 -50 39 -21 22 -43 39 -49 38 -6 -1 -10 4 -8 11 1 8
-1 11 -6 8 -5 -3 -22 8 -37 24 -15 16 -31 27 -35 25 -4 -2 -26 16 -50 40 -24
24 -47 42 -52 39 -4 -3 -12 5 -17 18 -6 13 -10 19 -10 14 -1 -6 -14 3 -31 19
-16 16 -30 25 -30 20 0 -5 -25 15 -55 45 -30 30 -55 50 -55 45 0 -5 -18 9 -40
30 -22 21 -40 35 -40 30 0 -5 -26 16 -58 48 -32 32 -60 55 -63 52 -3 -3 -16 6
-29 20 l-23 25 -294 0 c-272 0 -293 -1 -294 -17 -1 -10 -5 -808 -9 -1773 l-7
-1755 -25 -96 c-33 -122 -67 -189 -131 -257 -89 -94 -192 -132 -355 -132 -233
0 -395 107 -467 310 -15 41 -16 270 -15 2390 0 1770 4 2355 12 2385 7 23 12
105 13 190 0 127 -4 163 -24 235 -48 176 -133 320 -264 452 -134 134 -300 231
-477 279 -59 16 -93 31 -112 50 l-28 27 0 650 0 650 33 44 c18 24 51 52 72 63
39 19 65 20 760 25 l720 5 78 39 c125 63 194 134 246 255 22 53 23 64 25 363
1 353 -6 329 101 367 193 71 344 210 418 386 14 33 32 103 41 156 16 92 16
100 -4 197 -18 91 -31 128 -84 228 -26 49 -134 162 -195 203 -144 98 -248 131
-426 136 -82 3 -154 0 -195 -8z m315 -336 c176 -62 280 -248 231 -412 -30 -98
-105 -181 -205 -227 -49 -23 -70 -26 -161 -26 -117 -1 -155 10 -241 71 -57 41
-89 81 -120 153 -31 72 -32 174 -1 241 42 90 131 170 230 205 57 20 202 18
267 -5z m-4545 -2407 c108 -42 183 -110 229 -207 21 -43 23 -59 19 -145 -5
-114 -24 -157 -99 -230 -81 -80 -162 -112 -282 -112 -163 0 -263 53 -354 187
-13 19 -23 42 -23 51 -1 9 -7 28 -15 42 -21 36 -18 107 6 175 42 119 121 195
254 244 49 17 75 21 140 17 44 -2 100 -12 125 -22z m2628 -1473 c199 -56 337
-165 418 -329 42 -88 58 -176 50 -282 -6 -75 -38 -181 -63 -211 -7 -8 -13 -19
-13 -23 0 -14 -86 -112 -135 -155 -97 -83 -282 -150 -416 -151 -67 0 -194 25
-260 51 -162 63 -294 196 -354 355 -23 60 -26 85 -26 183 -1 93 4 125 22 175
12 33 27 68 34 76 6 8 17 28 25 43 37 74 171 187 275 232 131 56 316 72 443
36z"/>
<path d="M11135 1938 c-3 -7 -4 -191 -3 -408 l3 -395 265 -3 c240 -2 271 -1
330 17 109 33 182 110 216 227 22 78 23 249 0 325 -32 113 -120 203 -223 230
-72 18 -581 25 -588 7z m512 -244 c45 -33 58 -70 58 -159 0 -144 -41 -177
-207 -173 l-103 3 -3 169 c-2 129 1 172 10 178 7 5 59 7 115 6 84 -3 107 -7
130 -24z"/>
<path d="M12259 1928 c-17 -31 -309 -778 -309 -789 0 -6 56 -8 133 -7 l132 3
50 127 c28 70 66 168 84 217 19 50 37 91 40 91 4 0 44 -98 91 -218 l85 -217
133 -3 c75 -1 132 1 132 7 0 11 -33 99 -125 331 -40 102 -96 246 -125 320 -29
74 -59 141 -68 148 -11 8 -53 12 -129 12 -106 0 -113 -1 -124 -22z"/>
<path d="M12898 1943 c-17 -4 -18 -32 -18 -409 l0 -404 125 0 125 0 0 193 c0
107 3 197 6 200 6 6 61 -61 247 -300 l72 -92 128 -1 127 0 0 405 0 405 -125 0
-125 0 0 -195 c0 -107 -3 -195 -7 -194 -8 0 -22 17 -205 250 l-107 136 -62 7
c-67 7 -154 6 -181 -1z"/>
<path d="M19305 1940 c-142 -4 -188 -9 -220 -23 -91 -41 -134 -112 -135 -221
0 -94 17 -143 66 -193 60 -60 115 -74 304 -79 85 -3 165 -7 178 -10 24 -5 30
-28 10 -41 -7 -4 -109 -10 -227 -13 l-213 -5 -54 -100 c-30 -55 -54 -106 -54
-112 0 -17 534 -18 612 -2 64 14 115 39 145 71 50 54 72 159 53 253 -10 48
-20 66 -59 104 -25 26 -64 52 -86 60 -24 8 -113 16 -222 20 -183 6 -212 13
-200 44 8 22 55 27 242 27 l173 0 59 108 60 107 -51 7 c-28 4 -85 6 -126 5
-41 -1 -156 -4 -255 -7z"/>
<path d="M19867 1943 c-18 -5 -18 -19 -15 -271 4 -248 6 -271 26 -323 64 -161
190 -230 398 -217 148 10 245 69 299 183 34 72 47 202 43 440 l-3 190 -125 0
-125 0 -5 -245 c-5 -268 -9 -288 -62 -320 -42 -25 -84 -25 -125 0 -63 38 -68
59 -71 322 -4 233 -4 236 -25 242 -25 7 -183 6 -210 -1z"/>
<path d="M20767 1944 c-4 -4 -7 -189 -7 -411 l0 -403 268 0 c283 0 325 6 398
51 79 48 118 193 80 294 -10 25 -22 45 -27 45 -15 0 -10 20 12 52 27 37 37
143 19 208 -17 62 -79 123 -150 146 -46 15 -99 18 -320 22 -146 2 -269 0 -273
-4z m487 -244 c9 -7 16 -16 16 -21 0 -4 -28 -38 -61 -74 l-62 -65 45 -48 c64
-69 84 -100 69 -115 -13 -13 -186 -23 -226 -13 l-25 6 0 169 c0 126 3 171 13
175 24 11 215 0 231 -14z"/>
<path d="M14290 1728 c0 -368 37 -478 187 -554 56 -28 63 -29 198 -29 135 0
142 1 199 30 75 38 128 100 157 184 20 60 23 89 27 324 l4 257 -125 0 -126 0
-3 -242 c-3 -270 -8 -292 -70 -324 -39 -20 -110 -14 -140 12 -46 41 -52 75
-56 322 l-4 232 -124 0 -124 0 0 -212z"/>
<path d="M15200 1540 l0 -400 118 0 c65 0 121 2 123 5 14 14 -17 198 -41 240
-7 12 -5 251 2 285 20 92 54 197 74 227 8 12 14 27 14 33 0 6 -52 10 -145 10
l-145 0 0 -400z"/>
<path d="M17160 1930 c0 -5 3 -10 8 -10 7 0 32 -48 32 -62 0 -5 9 -27 19 -51
22 -46 44 -146 43 -190 0 -18 3 -25 11 -20 9 5 9 3 0 -8 -9 -11 -10 -25 -4
-47 5 -18 6 -34 2 -37 -5 -2 -9 -24 -10 -47 -1 -57 -43 -211 -72 -270 -23 -45
-16 -48 111 -48 l120 0 0 400 0 400 -130 0 c-80 0 -130 -4 -130 -10z"/>
<path d="M17776 1907 c-7 -19 -17 -45 -21 -58 -4 -13 -11 -28 -15 -34 -3 -5
-15 -32 -25 -60 -17 -49 -101 -263 -191 -492 -24 -62 -44 -115 -44 -118 0 -3
59 -4 131 -3 l132 3 34 90 c74 196 127 330 134 338 4 5 39 -74 78 -175 40
-100 79 -200 88 -220 l16 -38 132 0 133 0 -110 278 c-60 152 -127 324 -149
382 -60 155 -40 140 -184 140 l-125 0 -14 -33z"/>
</g>
</svg>






        
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}